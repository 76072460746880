<template>
  <div class="cummode" v-if="show && paramsData.name == '村委班子' 
                                  || paramsData.name == '总户数'
                                  || paramsData.name == '网格员'
                                  || paramsData.name == '护林员'
                                  || paramsData.name == '应急灭火队'
                                  || paramsData.name == '常住人口'
                                  || paramsData.name == '外来人口'
                                  || paramsData.name == '流动人口'
                                  || paramsData.name == '党委班子'" style="z-index: 9999">
    <div class="cun_inner" >
      <div class="modeTop">
        <div class="flex_center_align">
          <div style="margin-left:16px">{{ paramsData.name }}</div>
        </div>
        <div class="modeTop_close" @click="close">
          <img class="mode_img" src="@/assets/img/close1.png"/>
        </div>
      </div>
      <!-- 人物图片 -->
      <div class="box" v-if="paramsData.showType=='peopleImg'">
        <div class="peoples_box">
          <div class="people_item" v-for="(item,index) in peopleList" :key="index" @click="showDetails(item)">
            <div class="peoples_img" :class="'item_width'">
              <img style="width:100%;height:100%"  :src="item.avatarUrl" v-if="item.avatarUrl"/>
              <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
            </div>
            <div class="peoples_name">{{item.nickName||item.fullName}}</div>
            <div class="peoples_name" style="padding-top:0" v-if="paramsData.name == '村委班子'">{{item.positionName}}</div>
            <div class="peoples_name" style="padding-top:0" v-if="paramsData.name == '党委班子'">{{item.partyPosition}}</div>
          </div>
        </div>
        
        <template  v-if="paramsData.type=='cunMembers'" >
            <div v-for="(item,index) in morePeoples" :key="index">
              <div class="se_title new_cunTitle">{{ item.committeeName }}</div>
            <div class="peoples_box" >
              <div class="people_item" v-for="(item,index) in item.memberList" :key="index" @click="showDetails(item)">
                <div class="peoples_img" :class="'item_width'">
                  <img style="width:100%;height:100%"  :src="item.avatarUrl" v-if="item.avatarUrl"/>
                  <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
                </div>
                <div class="peoples_name">{{item.nickName||item.fullName}}</div>
                <div class="peoples_name" style="padding-top:0">{{item.committeeName}}</div>
              </div>
            </div>
            </div>
        </template>
      </div>
        <!-- 常驻人口、外来人口、流动人口表格 -->
        <div class="box" v-if="paramsData.showType=='tableList'">
          <div class="people_table" v-for="(item,index) in numsForm" :key="index">
            <!-- <div class="se_title">{{ item.areaManagementName }}</div> -->
            <div class="se_title1" @click="toggleContent(index)">
              <span class="square"></span>
              <span style="margin-left:10px">{{ item.areaManagementName }}</span>
              <i class="arrow" :class="{'down': item.showContent, 'right': !item.showContent}" ></i>
            </div>
            <div v-show="item.showContent">
              <div class="title flex_center_align flex_center">
                <div class="cun_num flex_colum flex_center">
                  <div>{{ item.familyNum }}</div>
                  <span>户数</span>
                </div>
                <div class="cun_num flex_colum flex_center">
                  <div>{{ item.residentNum }}</div>
                  <span>人口数</span>
                </div>
                <div class="cun_num flex_colum flex_center">
                  <div>{{ item.flowNum }}</div>
                  <span>流动人口数</span>
                </div>
              </div>
              <div class="title">详情</div>
              <div class="table_box">
                <el-table stripe :data="item.familyMaintainList" v-loading="loading" height="500">
                  <el-table-column label="村/组" align="center" prop="managementFull" />
                  <el-table-column label="门牌号/商铺名" align="center" prop="storehouseNumber" width="120" />
                  <el-table-column label="户主" align="center" prop="famName" />
                  <el-table-column label="流动人口" align="center" prop="famCountWai" />
                  <el-table-column label="总人口" align="center" prop="famCount" />
                  <el-table-column label="" align="center" class-name="small-padding fixed-width">
                    <template slot-scope="scope">
                      <img src="../assets/img/r-arrow.png" alt="" @click.stop="handleShow(scope.row)" style="width:30px;height:30px;cursor: pointer;" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>

        <div class="box" v-if="paramsData.showType=='gridList'">
          <el-collapse accordion @change="handleChange">
            <el-collapse-item v-for="(item,index) in villageList" :key="index" :name="item.id">
              <template slot="title" >
                <div class="title_box">
                  <div class="title_box_left">
                    <div class="one"></div>
                    <div class="two">{{item.name}}</div>
                  </div>
                  <div class="title_box_right">
                    <div class="one">户数：{{item.fmNums}}</div>
                    <div class="two" style="margin-left:20px">人口：{{item.trNums}}</div>
                  </div>
                </div>
              </template>
              <div class="item_box" v-for="(teamItem,index) in villageListTeam" :key="index">
                <img class="img" :src="teamItem.avatarUrl" alt="">
                <div class="user">
                  <div class="user_name">
                    <div>{{teamItem.fullName}}</div>
                    <div>{{teamItem.mobile}}</div>
                  </div>
                  <div class="user_add">{{teamItem.addresDetails}}</div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>


        <div class="box" v-if="paramsData.showType=='description'">
          <div class="people_table" v-for="(item,index) in numsForm" :key="index">
            <div class="se_title1" @click="toggleContent(index,'description')">
                <span class="square"></span>
                <span style="margin-left:10px">{{ item.areaManagementName }}</span>
                <i class="arrow" :class="{'down': item.showContent, 'right': !item.showContent}" ></i>
              </div>
            <!-- <div class="se_title">{{ item.areaManagementName }}</div> -->
              <div v-show="item.showContent">
                <!-- <div class="title flex_center_align flex_center">
                  <div class="cun_num flex_colum flex_center">
                    <div>{{ item.familyNum }}</div>
                    <span>户数</span>
                  </div>
                  <div class="cun_num flex_colum flex_center">
                    <div>{{ item.residentNum }}</div>
                    <span>人口数</span>
                  </div>
                  <div class="cun_num flex_colum flex_center">
                    <div>{{ item.flowNum }}</div>
                    <span>流动人口数</span>
                  </div>
                </div> -->
                <div class="title">详情</div>
                <div class="table_box">
                  <el-table stripe :data="item.familyMaintainList" v-loading="loading" height="500">
                    <el-table-column label="村/组" align="center" prop="managementFull" />
                    <el-table-column label="门牌号/商铺名" align="center" prop="storehouseNumber" width="120" />
                    <el-table-column label="户主" align="center" prop="famName" />
                    <el-table-column :label="dynamicLabel" align="center" prop="famCountChang" v-if="dynamicLabel == '常住人口'"/>
                    <el-table-column :label="dynamicLabel" align="center" prop="famCountWai" v-else-if="dynamicLabel == '流动人口'"/>
                    <el-table-column :label="dynamicLabel" align="center" prop="foreignCount" v-else-if="dynamicLabel == '外来人口'"/>
                    <el-table-column label="总人口" align="center" prop="famCount" />
                    <el-table-column label="" align="center" class-name="small-padding fixed-width">
                      <template slot-scope="scope">
                        <img src="../assets/img/r-arrow.png" alt="" @click.stop="handleShow(scope.row)" style="width:30px;height:30px;cursor: pointer;" />
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
            </div>
          </div>
        </div>

    </div>
      <!-- 简介 -->
      <div class="cummode" v-if="itemShow" style="z-index:1000">
        <div class="cun_inner" >
          <div class="modeTop">
            <div class="flex_center_align">
              <div style="margin-left:16px">{{itemData.nickName||itemData.fullName }}简介</div>
            </div>
            <div class="modeTop_close" @click="close('item')">
              <img class="mode_img" src="@/assets/img/close1.png"/>
            </div>
          </div>
          <div class="box">
            <div class="details_box">
              <div class="people_item" style="width: 14.5%;margin: 0 auto;">
                <img style="width:100%;height:100%"  :src="itemData.avatarUrl" v-if="itemData.avatarUrl"/>
                <img style="width:100%;height:100%"  src="@/assets/img/user.png" v-else/>
                <div class="peoples_name">{{itemData.nickName||itemData.fullName}}</div>
              </div>
              <div class="people_inc"> {{itemData.remarks}} </div>
            </div>
          </div>
        </div>
      </div>

    <wg-mode ref="wgMode"></wg-mode>
  </div>

  <div class="cummode" v-else-if="show && paramsData.name == '村情信息'">
    <div class="cun_inner">
        <div class="modeTop">
          <div class="flex_center_align">
            <div style="margin-left:16px">{{paramsData.name}}</div>
          </div>
          <div class="modeTop_close" @click="close">
            <img class="mode_img" src="@/assets/img/close1.png"/>
          </div>
        </div>
        <!-- 人物图片 -->
        <div class="box">
          <div class="peoples_box">
            <div class="people_item cun_item">
              <span style="margin-bottom: 20px;display: block;">
                小平房村位于建平县城东南 4.5公里处，隶属于万寿街道办事处。辖 7 个自然屯，13 个村民组，833 户，3307口人，总面积 32.34 平方公里，耕地面积 5303 亩。2007 年成立朝阳市第一个村级党委，下设 8 个党支部，有党员 124 名。到 2022 年底，已拥有固定资产 3 亿元，生产总值实现 5 亿元，人均纯收入 2.8 万元。先后荣获“全国文明村镇”“全国创先争优先进基层党组织”“全国民主法治示范村”全国产业兴旺十佳村”“中国美丽休闲乡村”“全国休闲农业与乡村旅游示范村”等荣誉称号。
              </span>
              <img class="people_img" src="@/assets/img/cun0.jpg"/>
              <img class="people_img" src="@/assets/img/cun1.jpg"/>
            </div>
          </div>
          </div>
        </div>
    <wg-mode ref="wgMode"></wg-mode>
  </div>
</template>

<script>
import WgMode from './wgModeNew.vue'
import { getAllGrids, getAllMembers, getResidents, getResidentDetail, getAllPartys, getAllPartyMembers, getAllPartyTable, getAllForests, getAllCommittee, getAllPartyer, villageListTeam } from '@/api/api.js'
import { Loading } from 'element-ui'
// import { url } from 'inspector'
export default {
  components: { WgMode },
  data() {
    return {
      show: false,
      itemShow: false,
      peopleList: [],
      paramsData: {},
      loading: true,
      itemData: {},
      numsForm: [],
      dynamicLabel: '',
      partyShow: false,
      partyData: [],
      tables: { cpu: { cpuNum: 0, used: 0, sys: 0, free: 0 } },
      morePeoples: [],
      partyList: [
        { name: '钱如恒', date: '1947.05.01', memeber: '河南党支部' },
        { name: '潘爽', date: '1963.06.01', memeber: '愁水坑党支部' },
        { name: '潘合', date: '1968.01.01', memeber: '愁水坑党支部' },
        { name: '刘荣', date: '1968.07.01', memeber: '愁水坑党支部' },
        { name: '李凤珍', date: '1968.07.01', memeber: '愁水坑党支部' },
        { name: '邹吉福', date: '1968.11.01', memeber: '河北党支部' },
        { name: '邹吉全', date: '1968.11.01', memeber: '河北党支部' },
        { name: '梁玉祥', date: '1969.03.01', memeber: '河北党支部' },
        { name: '梁广江', date: '1969.05.01', memeber: '河北党支部' },
        { name: '李开友', date: '1969.05.01', memeber: '河南党支部' },
        { name: '马凤英', date: '1969.07.01', memeber: '河南党支部' },
        { name: '孙玉彩', date: '1970.03.01', memeber: '石灰窑子党支部' },
        { name: '任永军', date: '1970.06.01', memeber: '石灰窑子党支部' },
        { name: '王  林', date: '1972.12.07', memeber: '石灰窑子党支部' },
        { name: '李殿生', date: '1973.04.10', memeber: '石灰窑子党支部' }
      ],
      villageList: [],
      villageListTeam: []
    }
  },
  methods: {
    handleChange(val) {
      console.log('-------val-----', val)
      if (val) {
        this.loading = true
        getAllGrids({ regionId: this.paramsData.regionId, areaManagementId: val }).then(res => {
          // console.log('网格与======>',res);
          if (res.code == '0') {
            res.model.map(item => {
              if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
            })
            this.villageListTeam = res.model
            this.loading = false
          } else {
            this.loading = false
          }
        })
      } else {
        this.villageListTeam = []
      }
    },

    sexFormat(row, column) {
      if (row.memberSex == '1') {
        return '男'
      } else {
        return '女'
      }
    },
    toggleContent(index, type) {
      if (type == 'description') {
        this.numsForm[index].showContent = !this.numsForm[index].showContent
      } else {
        console.log('index', index)
        this.numsForm[index].showContent = !this.numsForm[index].showContent
        console.log('this.numsForm[index].showContent', this.numsForm[index].showContent)
      }
    },
    initData(data) {
      // console.info('zujian', data)

      this.loading = true
      this.paramsData = data
      //网格员
      if (data?.type && data.type === 'griders') {
        this.getallResident(data.regionId)
      }
      if (data.type === 'residentNum') {
        // this.peopleList = [{name:'孙国臣',url:require(url:'@/ass')}]
      }
      //村委成员
      if (data.type === 'cunMembers') {
        this.getMembers()
        // if(){
        this.getCommittee(data.regionId)
        // }
      }
      //总户数 --展示列表
      if (data.type === 'familyNum' || data.type === 'oftenNum' || data.type === 'foreignNum' || data.type === 'flowNum') {
        this.getallHu(data.regionId, data.type)
      }
      if (data.type === 'partyorganize') {
        this.getPartys(data.regionId)
      }
      //党员基本信息表格
      if (data.type === 'partybase') {
        this.getPartyTable(data.regionId)
      }
      // 护林员
      if (data.type == 'rangerNum') {
        this.getFore('forest')
      }
      //森林防火领导组
      if (data.type == 'forestPreNum') {
        this.getFore('forestPre')
      }
      //应急灭火队
      if (data.type == 'impatientNum') {
        this.getFore('impatient')
      }
      this.loading = false

      this.show = true
      // this.tableList = data.tableList;
    },
    close(type) {
      if (type == 'item') {
        this.itemShow = false
        this.itemData = {}
      } else if (type == 'party') {
        this.partyShow = false
        this.partyData = []
      } else {
        this.show = false
        this.numsForm = []
        this.peopleList = []
        this.paramsData = {}
      }
    },
    handleShow(event) {
      getResidentDetail({ familyId: event.id }).then(res => {
        if (res.code == 0) {
          if (res.model && res.model.length) {
            this.$refs.wgMode.initData(event)
            this.$refs.wgMode.wgList = res.model
          } else {
            this.$message.error('当前户无人居住')
          }
        }
      })
    },

    getallResident(regionId) {
      // this.peopleList = []
      this.loading = true
      villageListTeam({ villageId: regionId }).then(res => {
        console.log('===群组数据===>', res)
        if (res.code == '0') {
          this.villageList = res.model
        }
      })
      // getAllGrids({ regionId: regionId }).then(res => {
      //   console.log('网格与======>',res);
      //   if (res.code == '0') {
      //     res.model.map(item => {
      //       if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
      //     })
      //     this.peopleList = res.model
      //     this.loading = false
      //   } else {
      //     this.loading = false
      //   }
      // })
    },
    //人员详情
    showDetails(item) {
      if (!item.remarks) {
        return
      }
      this.itemShow = true
      this.itemData = item
    },
    //村委班子
    getMembers() {
      this.peopleList = []
      if (this.paramsData.name == '党委班子') {
        getAllMembers({ villageFlag: 1 }).then(res => {
          if (res.code == '0') {
            res.model.forEach(item => {
              if (item.memberName) item.nickName = item.memberName
              if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
            })
            this.peopleList = res.model
          }
        })
      } else {
        getAllMembers({ partyFlag: 1 }).then(res => {
          if (res.code == '0') {
            res.model.forEach(item => {
              if (item.memberName) item.nickName = item.memberName
              if (item.avatarUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.avatarUrl
            })
            this.peopleList = res.model
          }
        })
      }
    },
    getCommittee(id) {
      if (this.paramsData.name == '村委班子') {
        getAllCommittee({ villageId: id }).then(res => {
          if (res.code == '0') {
            res.model.forEach(item => {
              if (item.memberList.length) {
                item.memberList.map(itemData => {
                  if (itemData.rangerName) itemData.nickName = itemData.rangerName
                  if (itemData.imgUrl) itemData.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + itemData.imgUrl
                })
              }
            })
            this.morePeoples = res.model
          }
        })
      }
    },
    getallHu(villageId, type) {
      let populationType
      if (type == 'oftenNum') {
        populationType = '1'
        this.dynamicLabel = '常住人口'
      } else if (type == 'flowNum') {
        populationType = '2'
        this.dynamicLabel = '流动人口'
      } else if (type == 'foreignNum') {
        populationType = '3'
        this.dynamicLabel = '外来人口'
      }
      this.loading = true
      this.numsForm = []
      let data = {}
      if (populationType) {
        data = { villageId: villageId, villageIds: villageId, familyType: populationType }
      } else {
        data = { villageId: villageId, villageIds: villageId }
      }
      getResidents(data).then(res => {
        if (res.code == '0') {
          if (res.model.length) {
            res.model.map(resmode => {
              if (resmode.familyMaintainList && resmode.familyMaintainList.length) {
                resmode.familyMaintainList.map(i => {
                  i.managementFull = i.managementName + i.houseNumber
                  i.storehouseNumber = i?.store ? i.store : i.houseNumber
                })
              }
              resmode.showContent = false
            })
          }
          this.numsForm = res.model
          this.loading = false
        } else {
          this.loading = false
        }

        console.log('this.numsForm', this.numsForm)
      })
    },

    handleShow(event) {
      getResidentDetail({ familyId: event.id }).then(res => {
        if (res.code == 0) {
          if (res.model && res.model.length) {
            this.$refs.wgMode.initData(event)
            this.$refs.wgMode.wgList = res.model
          } else {
            this.$message.error('当前户无人居住')
          }
        }
      })
    },
    //党组织架构
    getPartys(villageId) {
      this.peopleList = []
      getAllPartys({ villageId: villageId }).then(res => {
        if (res.code == '0') {
          this.peopleList = res.model
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    partyfunction(item) {
      if (!item.memberNums || item.memberNums == 0) {
        return
      }
      getAllPartyMembers({ branchId: item.id }).then(res => {
        if (res.code == '0') {
          this.partyData = res.model
          this.partyShow = true
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    getPartyTable(villageId) {
      // getAllPartyTable({ villageId: villageId }).then(res => {

      // })
      this.morePeoples = []
      getAllPartyer({ memberType: 5 }).then(res => {
        if (res.code == '0') {
          if (res.model.length) {
            res.model.forEach(item => {
              if (item.memberList.length) {
                item.memberList.map(itemData => {
                  if (itemData.avatarUrl) itemData.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + itemData.avatarUrl
                  if (itemData.memberName) itemData.nickName = itemData.memberName
                })
              }
            })
          }
          this.morePeoples = res.model
        }
      })
    },
    getFore(type) {
      this.peopleList = []
      getAllForests({ rangerType: type }).then(res => {
        if (res.code == '0') {
          if (res.model.length) {
            res.model.forEach(item => {
              if (item.imgUrl) item.avatarUrl = 'https://xiaopingfang.baize-agri.cn/prod/api' + item.imgUrl
              if (item.rangerName) item.nickName = item.rangerName
            })
          }
          this.peopleList = res.model
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-collapse {
  // background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);
  // border: none;
  width: 96%;
  margin: 3% 2% 0;
  border: none;

  .el-collapse-item {
    //  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);
    margin-bottom: 5px;
  }
  /deep/ .el-collapse-item__header {
    background: linear-gradient(90deg, #182d68 0%, rgba(13, 24, 54, 0) 100%);
    color: #ffffff;
    border: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  // .el-collapse-item__header{
  //   background-color: #adffe6;
  // }
  .title_box {
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title_box_left,
    .title_box_right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .title_box_left {
      .one {
        height: 6px;
        width: 6px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 13px 1px rgba(255, 255, 255, 1);
        margin: 0 11px;
      }
    }
  }
  /deep/ .el-collapse-item__arrow {
    margin: 0 !important;
  }
  /deep/.el-collapse-item__wrap {
    background-color: inherit;
    border: none;
  }

  /deep/.el-collapse-item__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .item_box {
    width: 259px;
    height: 94px;
    background: linear-gradient(90deg, #12224d 0%, rgba(13, 24, 54, 0) 100%);
    margin-top: 20px;
    margin-right: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 15.5px 17px;

    .img {
      display: block;
      width: 53px;
      height: 63px;
      margin: 0 10px 0 17px;
    }
    .user {
      // margin-top: 16px;
      height: 63px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .user_name {
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
          height: 20px;
          color: #fff;
        }
        div:nth-child(1) {
          font-family: Source Han Sans CN;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
          margin-right: 14px;
        }
        div:nth-child(2) {
          font-family: Source Han Sans CN;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
      .user_add {
        font-family: Source Han Sans CN;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        height: 36px;
        width: 176px;
        overflow: hidden;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .item_box:nth-child(4n) {
    margin-right: 0;
  }
  .item_box:last-child {
    margin-right: auto;
  }
}

.cummode {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  color: #fff;
  .cun_inner {
    width: 1139px;
    height: 734px;
    position: absolute;
    left: 391px;
    top: 173px;
    z-index: 1000;
    padding: 27px 10px;
    border-radius: 10px;
    animation-name: small-to-big;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    @keyframes small-to-big {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
    .modeTop {
      width: 100%;
      height: 42px;
      background-image: url('../assets/img/titlebg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: JDLangZhengTi;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 7px;

      &_img {
        margin-left: 14px;
      }
      &_close {
        width: 45px;
        height: 45px;
        margin-right: 8px;
      }
    }

    .mode_content {
      width: 100%;
      height: 685px;
      overflow-y: scroll;
      background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);

      &_box {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 26px 0 26px 26px;
      }
      &_item {
        width: 254px;
        height: 219px;
        border-radius: 10px;
        overflow: hidden;
        background: #00000026;
        margin: 0 20px 20px 0;
      }
      &_img {
        width: 254px;
        height: 149px;
        border-radius: 10px 10px 0px 0px;
        width: 100%;
        height: 149px;
        border-radius: 10px 10px 0 0;
      }
      &_title {
        font-family: SourceHanSansCN;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        margin: 12px 10px 8px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 溢出部分隐藏 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
      }
      .mode_avatar_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        .mode_avatar {
          width: 14px;
          height: 14px;
          border-radius: 2px;
          overflow: hidden;
          margin: 0 4px 0 10px;
        }
        .mode_date {
          margin-right: 10px;
        }
      }
    }

    .mode_bank_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 410px;
      height: 45px;
      background: linear-gradient(90deg, #182d68 0%, rgba(13, 24, 54, 0) 100%);
      margin: 13px auto 0;
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
      &_img {
        width: 28px;
        height: 28px;
        border-radius: 5px;
        overflow: hidden;
        margin-left: 10px;
      }
      &_title {
        margin-left: 7px;
      }
      &_r {
        width: 35px;
        height: 35px;
      }
    }
    .mode_line_name {
      width: 300px;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 使用省略号显示 */
    }

    .box {
      width: 100%;
      height: 685px;
      overflow-y: scroll;
      background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);
      .party_nums {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 8px auto;
        .party_item {
          flex: 1;
        }
        .party_title {
          display: inline-block;
          width: 58%;
          text-align: right;
        }
      }
      .new_title {
        padding: 10px 0;
        font-weight: 800;
        margin: 20px auto 0;
      }
      .word {
        font-size: 16px;
        color: #fff;
        line-height: 30px;
        // text-indent: 2em;
        video {
          width: 180px;
          height: 180px;
        }
        img {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
        }
      }
      .word /deep/ img {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ video {
        width: 96%;
        height: 100%;
        background: #adffe6;
        border: 4px solid #707070;
        border-radius: 2px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
      .word /deep/ table {
        width: 100% !important;
        box-sizing: border-box !important;
        border-right: 1px solid grey !important;
        border-bottom: 1px solid grey !important;
        border-spacing: 0px !important;
        tbody {
          border-spacing: 0px !important;
        }
        tbody tr:nth-child(1) {
          text-align: center !important;
        }
        th {
          padding: 4px !important;
          font-size: 15px !important;
          font-weight: bold !important;
          border: 1px solid grey !important;
        }
        tr {
          border-color: inherit !important;
          border: 1px solid grey !important;
        }
        td {
          padding: 4px !important;
          font-size: 14px !important;
          border-top: 1px solid grey !important;
          border-left: 1px solid grey !important;
          text-align: center !important;
        }
      }

      .imgbox {
        margin-top: 20px;
        flex-wrap: wrap;
        .video {
          width: 100%;
          height: 100%;
        }
        .img_item {
          width: 180px;
          height: 180px;
          background: #adffe6;
          border: 4px solid #707070;
          border-radius: 2px;
          margin-left: 10px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .peoples_box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: space-between;
        .people_item {
          width: 33.3%;
          text-align: center;
        }
        .peoples_img {
          width: 450px;
          height: 296px;
          margin: 0 auto;
          display: block;
          // box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 0.7);
        }
        .item_width {
          width: 250px;
          height: 350px;
        }
        .peoples_name {
          font-size: 20px;
          padding: 15px 0;
        }
        .cun_item {
          margin-top: 20px;
          text-align: left;
          text-indent: 2em;
          width: 94%;
          margin: 20px auto;
          .people_img {
            width: 100%;
            margin: 20px auto;
          }
        }
      }
      .details_box {
        display: flex;
        align-items: flex-start;
        .people_item {
          width: 20.5%;
          text-align: center;
        }
        .peoples_img {
          width: 96%;
          max-height: 430px;
          margin: 0 auto;
          display: block;
        }
        .peoples_name {
          font-size: 22px;
          padding: 15px 0;
        }
        .people_inc {
          margin: 0 auto;
          width: 70%;
          font-size: 20px;
          line-height: 32px;
        }
      }
      .party_left {
        writing-mode: tb-rl;
        letter-spacing: 40px;
        font-size: 40px;
        height: 620px;
        text-align: center;
        background: #cd1010;
        border-radius: 20px;
        border: 4px solid #fff;
        padding: 0 5px;
      }
      .party_right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        &_item {
          width: 230px;
          height: 400px;
          position: relative;
          background-image: url('../assets/img/zubg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin: 15px 25px;
          cursor: pointer;
          &_center {
            background: #fff;
            width: 200px;
            height: 230px;
            margin: auto;
            position: absolute;
            left: 50%;
            margin-left: -105px;
            top: 50%;
            margin-top: -100px;
          }
        }
        &_title {
          color: #cd1010;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin: 15px 0;
          letter-spacing: 5px;
        }
        &_shu {
          color: #131212;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin: 15spx 0;
          letter-spacing: 5px;
        }
      }
    }
    .inclu {
      font-size: 18px;
      letter-spacing: 3px;
      text-indent: 25px;
      line-height: 40px;
      padding: 0 0 30px;
    }
    .title {
      font-size: 22px;
      color: #fff;
      text-align: left;
      padding: 10px 20px;
      .cun_num {
        flex: 1;
        text-align: center;
      }
    }
    .table_box {
      height: auto;
      padding: 0 10px 20px;
    }
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 10000;
      font-size: 29px;
      color: #fff;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
    }
    .table {
      width: 80%;
      margin: 0 auto;
      font-size: 24px;
      border-collapse: collapse;
      th,
      td {
        height: 22px;
        border: 1px solid rgb(206, 199, 199);
      }
    }
    .nclose {
      font-size: 42px;
      right: 25px;
      top: 15px;
    }
  }
  .new_cunTitle {
    margin: 100px 0px 50px;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
  }
  .people_table {
    width: 1055px;
    margin: 0 auto;
  }
  .se_title1 {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 42px;
    background: linear-gradient(to right, #1c2d5c, rgba(28, 45, 92, 0.04));
    margin-top: 10px;
    .square {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-left: 11px;
      background-color: white;
      // border: 1px solid white;
      box-shadow: 0px 0px 12px 1px #ffffff;
    }
  }
  .arrow {
    margin-left: auto;
    width: 10px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
    margin-right: 15px;
  }
  .down {
    transform: rotate(135deg);
  }
  .content {
    padding: 10px;
    display: none;
    width: auto;
    height: auto;
  }
}
.box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #0d53b7;
  -webkit-box-shadow: inset 0 0 5px #0d53b7;
  background: #535353;
}
.box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: transparent;
}
</style>
<style lang="scss">
.el-table {
  background-color: transparent !important;
}
.el-table th.el-table__cell,
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent !important;
}
.el-table tr {
  background-color: transparent !important;
}
.el-table .cell {
  color: #fff;
}
.el-table th.el-table__cell > .cell {
  color: #fff;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: transparent !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  -webkit-box-shadow: inset 0 0 5px rgba(29, 133, 197, 1) !important;
  background: #535353 !important;
}
.el-table .el-table__body-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px !important;
  background: #ededed !important;
}
</style>
