<template>
  <div>
   <v-scale-screen :fullScreen="false" width="1920" height="1080">
    <div class="newScreen1">
        <div class="videobg">
          <div class="overlay"></div>
          <video autoplay muted loop objectFit="cover" style="width:100%;height:100%;object-fit: cover">
            <source src="@/assets/img/bg.mp4" type="video/mp4">
          </video>
        </div>
        <!-- 顶部 -->
          <div class="top" >
            <div class="top_title">{{ topTitle }}</div>
            <div class="top_title_people">
            <div class="top">
              <img class="left" src="@/assets/img/tel_phone.png" alt="">
              <div class="right">
                服务终端在线人数
              </div>
            </div>
            <div class="bottom">{{$store.state.onlinePoeple}}</div>
          </div>
          </div>
          <div class="cont">
          <!-- 左 村情信息--> 
          <div class="contLeft">
            <div class="nav_left">
              <div class="flex_space flex_align" style="margin-left: 46px;width: 503px;">
                <div class="nav_left_item" v-for="(item,index) in leftList" :key="index" @click="showMode(item,index)">
                    <div class="nav_left_img"><img  :src="item.icon" style="width:100%;height:100%"/></div>
                    <div class="nav_left_name">{{item.name}}</div>
                    <div class="nav_left_num">{{ item.num || 0 }}</div>
                </div>
                <img src='@/assets/img/return.png'  style="height:70px;width:70px;margin-left:8px;z-index:100;display:block" @click="goHome()"/>
            </div>
            </div>
            <div class="nsLeft" v-if="!num">
              <div class="left-center">
                  <div class="flex_space flex_center_align" style="margin-left:24px">
                    <div class="lc_title">村情信息</div>
                    <img class="lc_right" src="./assets/img/rarrow.png" @click="villageMode('村情信息','cunMembers')"/>
                  </div>
                  <div class="line"></div>
                  <div class="panel">
                    <div class="panel_cun" >
                      <div class="panel_cun_box">
                            <div class="flex_start">
                                <div class="circle"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('总户数','familyNum')">总户数</div>
                                  <div class="panel_cun_num">{{total.familyNum}}</div>
                                </div>
                            </div>
                            <div class="flex_start">
                                <div class="circle"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('网格员','griders')">网格员</div>
                                  <div class="panel_cun_num">{{total.adminUserNum}}</div>
                                </div>
                            </div>
                            <div class="flex_start">
                                <div class="circle"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('护林员','rangerNum')">护林员</div>
                                  <div class="panel_cun_num">{{total.rangerNum}}</div>
                                </div>
                            </div>
                            <div class="flex_start">
                                <div class="circle"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('应急灭火队','impatientNum')">应急灭火队</div>
                                  <div class="panel_cun_num">{{total.impatientNum}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="ageVue">
                          <div class="echartsBg">
                            <img class="age_img" src="./assets/img/popu.png"/>
                            <div class="age_num">{{total.residentNum}}</div>
                          </div>
                          <div  class="circle" ref="circle" style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;"></div>
                          <div class="echartsLeg">
                            <div class="flex_start">
                                <div class="circle" style="border-color:#F9B814"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('常住人口','oftenNum')">常驻人口</div>
                                  <div class="panel_cun_num">{{total.oftenNum}}</div>
                                </div>
                            </div>
  
                            <div class="flex_start">
                                <div class="circle" style="border-color:#9523CB"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('外来人口','foreignNum')">外来人口</div>
                                  <div class="panel_cun_num">{{total.foreignNum}}</div> 
                                </div>
                            </div>
  
                            <div class="flex_start">
                                <div class="circle" style="border-color:#4DB9A5"></div>
                                <div>
                                  <div class="panel_cun_name" style="cursor: pointer;" @click="villageMode('流动人口','flowNum')">流动人口</div>
                                  <div class="panel_cun_num">{{total.flowNum}}</div>
                                </div>
                            </div>
                             
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="left-center" style="margin-top:25px">
                  <div class="flex_space flex_center_align" style="margin-left:24px">
                    <div class="lc_title">村委架构</div>
                    <img class="lc_right" src="./assets/img/rarrow.png"  @click="villageMode('村委班子','cunMembers')"/>
                  </div>
                  <div class="line"></div>
                  <div class="panel" style="display: flex; justify-content: center;">
                    <video id="myVideo" autoplay muted loop controls @click="openVideoPopup"  :style="{ width: videoWidth + 'px', height: videoHeight + 'px' }">
                      <source src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/village/qcjssp.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
              </div>
            </div>

            <!-- 左 自然页面 -->
            <div class="nsLeft" v-else>
              <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">村情信息</div>
                </div>
                <div class="line"></div>
                <div class="panel one" style="overflow-y: scroll; ">
                  <div class="panel_cun" >
                    <div class="panel_cun_box1">
                      <div style="margin-left: 24px; font-size: 12px; font-family: Microsoft YaHei;">户籍人口</div>
                      <div>
                        <span style="margin-left: 175px; font-size: 30px; font-family: ShiShangZhongHeiJianTi; color: #FFFFFF; text-shadow: 0px 1px 10px #0099ff;">{{numberToCurrencyNo(domicileNum)}}</span>
                        <span style="margin-left: 8px; color: #0099FF; font-family: Microsoft YaHei;">人</span>
                      </div>
                    </div>
                    <div v-for="(item, index) in dataList" :key="index">
                      <colum-chart v-bind="item.elementValue"></colum-chart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-center" style="margin-top:25px">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">交通</div>
                </div>  
                <div class="line"></div>
                <div class="panel" style="height: 400px">
                  <div style="width: 325px; height: 32px; margin-top: 15px; margin-left: 31px;">
                    <span style="font-size: 12px; font-family: Microsoft YaHei;">小平房村境内有京四高速公路、锦承铁路(货运)，以及宁孤绕城，富关公路，叶北公路</span>
                  </div>
                  <div style="margin-left: 20px">
                  <div style="height: 10px;"></div>
                  <div class="traffic_font_class" v-for="(serv, index) in serviceIndustryList" :key="index">
                    <div> 
                      <span class="square"></span>
                      <span style="margin-left:9px;">{{ serv.title }}</span>
                    </div>
                    <img class="lc_right" src="./assets/img/5.png" style="float: right;" @click="changeMap(serv)"/>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 中 -->
          <div class="contCenter">
            <div class="nscenter">
              <!-- 地图 -->
              <div class="mapCss mapCity" v-if="mapshow">
                <div ref="mapBox" style="width:100%;height:100%"> </div>
                  <t-footer style="bottom: 410px; width: 20%; right: 23.5%;" :tabList="tabList" :status="'grid'" @tab="handlerTab" v-if="!num"></t-footer>
                  <t-footer style="bottom: 10px; width: 20%; right: 23.5%;" :tabList="tabList" :status="'grid'" @tab="handlerTab" v-else></t-footer>
              </div>
              <!-- 村史 -->
              <div class="center_bottom" v-if="!num">
                <div class="left-center center_bleft">
                    <div class="flex_space flex_center_align">
                      <div class="lc_title">村史</div>
                    </div>
                    <div class="line"></div>
                    <div class="panel" style="height: 350px;">
                      <Timeline :timelineList="timeLineArr" @handleTimelineClick="handleTimelineClick"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右 -->
          <div class="contRight"  v-if="!num">
            <div class="nsright">
              <div class="left-center"  style="margin-top: -35px;">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">自然信息</div>
                    <img class="lc_right" src="./assets/img/rarrow.png" @click="handlerTab('2')"/>
                  </div>
                  <div class="line"></div>
                  <div class="panel">
                    <div class="panel_top">
                    </div>
                      <div class="right_item">
                        <div class="flex_space">
                          <div class="right_item_box">
                            <div class="item_title">行政区类别</div>
                            <div>
                              <span class="muNum">村</span>
                            </div>
                          </div>
                          <div class="right_item_box">
                            <div class="item_title">区域面积</div>
                            <div>
                              <span class="muNum">32.34</span>
                              <span class="mu">平方公里</span>
                            </div>
                          </div>
                          <div class="right_item_box">
                            <div class="item_title">户籍人口</div>
                            <div>
                              <span class="muNum">{{total.residentNum}}</span>
                              <span class="mu">人</span>
                            </div>
                          </div>
                        </div>
                        <div class="flex_space">
                          <div class="right_item_box">
                            <div class="item_title">村民组</div>
                            <div>
                              <span class="muNum">13</span>
                            </div>
                          </div>
                          <div class="right_item_box" style="margin-right: 190px;">
                            <div class="item_title">电话区号</div>
                            <div>
                              <span class="muNum">0421</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="natureInfo2">
                      <img class="natureImg1" src="./assets/img/doubleComma.png"/>
                      <span style="color: #c4e6fe; margin-left: 15px; font-size: 18px; vertical-align: top;">简介</span> 
                      <img class="lc_right" style="margin-left: 20px; margin-right: 15px" src="./assets/img/rarrow.png" @click="goDetailInfo('小平房村介绍')"/>
                      <span class="natureImg2">|</span>
                      <img class="natureImg1" src="./assets/img/doubleComma.png" />
                      <span style="color: #c4e6fe; margin-left: 15px; font-size: 18px; vertical-align: top;">村委信息</span> 
                      <img class="lc_right" style="margin-left: 20px; margin-right: 0px; " src="./assets/img/rarrow.png" @click="goDetailInfo('小平房村委员会')"/>
                    </div>
                    <div ref="industry" style="display: flex;width:100%;height:170px;margin:0 auto">
                      <div class="natureInfo3" style="margin-left: 24px;">
                        <ul class="columnLayout">
                          <li>智慧数字乡村</li>
                          <li>集体经济</li>
                          <li>辽宁省和美乡村</li>
                        </ul>
                      </div>
                      <div class="natureInfo3" style="width: 170px;">
                        <ul class="columnLayout">
                          <li>6大部门</li>
                          <li>7个纲领</li>
                          <li>13个村民组</li>
                        </ul>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div class="nsbelow">
              <div class="left-center">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">关注人群</div>
                  </div>
                  <div class="line"></div>
                  <div class="panel">
                    <div class="panel">
                    <div class="panel_cun">
                      <div class="ageVue">
                        <div class="echartsBg">
                          <img class="age_img" src="./assets/img/popu.png"/>
                          <div class="age_num">{{peopleFormNum}}</div>
                        </div>
                        <div  class="circle" ref="circle12" style="width:100%;height:100%;border: 1px solid transparent;-webkit-tap-highlight-color: transparent;user-select: none;"></div>
                        <div class="echartsLeg">
                          <div class="flex_start">
                              <div class="circle" style="border-color:#F9B814"></div>
                              <div>
                                <div class="panel_cun_name">残疾人员</div>
                                <div class="panel_cun_num">{{peopleForm[0]?peopleForm[0].value:0}}</div>
                              </div>
                          </div>
                          <div class="flex_start">
                              <div class="circle" style="border-color:#9523CB"></div>
                              <div>
                                <div class="panel_cun_name">低保人员</div>
                                <div class="panel_cun_num">{{peopleForm[1]?peopleForm[1].value:0}}</div>
                              </div>
                          </div>
                          <div class="flex_start">
                              <div class="circle" style="border-color:#4DB9A5"></div>
                              <div>
                                <div class="panel_cun_name">留守人员</div>
                                <div class="panel_cun_num">{{peopleForm[2]?peopleForm[2].value:0}}</div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>  
          <div class="contRight"  v-else>
            <div class="nsright">
              <div class="left-center"  style="margin-top: -35px;">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">社会事业</div>
                </div>
                <div class="line"></div>
                <div class="panel">
                  <div class="panel_top">
                    <div class="panel_title" >文化事业</div>
                    <div class="p_line"></div>
                    <div class="traffic_font_class" style="margin-left: 0px" v-for="(serv, index) in culturalUndertList" :key="index">
                      <div> 
                        <span class="square"></span>
                        <span style="margin-left:9px;">{{ serv.title }}</span>
                      </div>
                      <img class="lc_right" src="./assets/img/5.png" style="float: right;"  @click="changeMap(serv)"/>
                    </div>
                    <div style="height:90px"></div>
                  </div>
                  <div class="panel_top">
                    <div class="panel_title" >教育事业</div>
                    <div class="p_line"></div>
                    <div class="flex_space" style="margin-top:23px">
                      <div class="right_item_box" >
                        <div class="item_title" style="font-size:12px">初中适龄人口入学率</div>
                        <div>
                          <span class="muNum">100</span>
                          <span class="mu">%</span>
                        </div>
                      </div>
                      <div class="right_item_box" style="margin-right: 74px; font-size:12px">
                        <div class="item_title">小升初升学率</div>
                        <div>
                          <span class="muNum">100</span>
                          <span class="mu">%</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex_space" >
                      <div class="right_item_box" >
                        <div class="item_title" style="font-size:12px">九年义务教育覆盖率</div>
                        <div>
                          <span class="muNum">100</span>
                          <span class="mu">%</span>
                        </div>
                      </div>
                    </div>
                    <div class="traffic_font_class" style="margin-left: 0px" v-for="(serv, index) in educationList" :key="index">
                      <div> 
                        <span class="square"></span>
                        <span style="margin-left:9px;">{{ serv.title }}</span>
                      </div>
                      <img class="lc_right" src="./assets/img/5.png" style="float: right;"  @click="changeMap(serv)"/>
                    </div>
                  </div>
                  <div class="panel_top">
                    <div class="panel_title" >医疗卫生</div>
                    <div class="p_line"></div>
                    <div class="flex_space" >
                      <div class="right_item_box" style="margin-top:23px;">
                        <div class="item_title" style="font-size:12px">新型农村合作医疗参合率</div>
                        <div>
                          <span class="muNum">93</span>
                          <span class="mu">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="panel_top">
                    <div class="panel_title" >社会保障</div>
                    <div class="p_line"></div>
                    <div class="traffic_font_class" style="margin-left: 0px" v-for="(serv, index) in guaranteeList" :key="index">
                      <div> 
                        <span class="square"></span>
                        <span style="margin-left:9px;">{{ serv.title }}</span>
                      </div>
                      <img class="lc_right" src="./assets/img/5.png" style="float: right;"  @click="changeMap(serv)"/>
                    </div>
                    <div style="height:80px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
      </div>
    </div>
    <newMode ref="newMode"></newMode>
    <!-- 村委班子 -->
    <num-mode ref="numMode"></num-mode>
    <!-- 村史 -->
    <wg-mode ref="wgMode"></wg-mode>
    <!-- 村介绍 -->
    <cun-mode ref="cunMode"></cun-mode>

    <div style="width: 100%;height: 100%;background: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 9999!important" v-if="showOne" >
      <div style="position: absolute;top:250px;left:500px;z-index: 999;height: 600px;width: 50%;margin: 0 auto;" >
        <div style="height: 42px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
          <img style="height: 42px;brightness(200%);float: left;" :src="require('@/assets/img/' + 'Vector.svg')"/>
          <span style="line-height: 40px;float: right;color: white;margin-right: 10px;font-size: 25px;" @click="close">×</span>
        </div>
        <div style="height: 5px;width: 100%;"></div>
        <div style="height: 500px;width: 100%;padding: 25px 0 25px;background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0.55) 100%);">
          <video style="width: 100%;height: 100%;" autoplay muted loop controls class="video" src="https://xiaopingfang.baize-agri.cn/prod/api/resources/user/village/qcjssp.mp4"></video>
        </div>
      </div>
    </div>
   </v-scale-screen>
  </div>
</template>
<script>
import WgMode from '@/components/wgModeNew.vue'
import newMode from '@/components/newMode.vue'
import numMode from '@/components/numModeNew.vue'
import CunMode from '@/components/cunModeNew.vue'
import { mapOption } from './utils/options.js'
import timeLine from '@/components/timeline.vue'
import tFooter from '@/components/footer.vue'
import ColumChart from '@/components/chart/ColumChart.vue'
import { mapdata, geoCoordMap, coordinates } from './utils/mapCenterPoint'
import { vipCarDateList, getOthers, getAnalysis, getpqDetail, getIndexStatistics, postLogin, getDangIndex, getLineClass, getBankList, articleInfoList, getHisItemsGroup, getHomeDetail, searchReadNums, vipCarList } from '@/api/api.js'
import { getID } from '@/utils/index.js'
const mapData = require('./utils/newcun.json')
import * as echarts from 'echarts'
export default {
  components: {
    CunMode,
    WgMode,
    tFooter,
    ColumChart,
    newMode,
    numMode
  },
  data() {
    return {
      villageIntrod: [],
      topTitle: '村情信息',
      cunHis: [{ title: 'title' }],

      leftList: [
        {
          name: '民众心声',
          icon: require('./assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('./assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('./assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('./assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('./assets/img/car.png'),
          type: '5',
          ptype: 'monitor',
          num: 0
        }
      ],
      total: { familyNum: 0, residentNum: 0, adminUserNum: 0, oftenNum: 0, foreignNum: 0, flowNum: 0, rangerNum: 0, impatientNum: 0 },
      peopleFormNum: 0,
      peopleForm: [],
      videoWidth: 342,
      videoHeight: 325,
      isFullScreen: false,
      nomore: false,
      num: '',
      baseForm: {},
      dataList: [],
      serviceIndustryList: [],
      culturalUndertList: [],
      educationList: [],
      guaranteeList: [],
      timeLineArr: [],
      tabList: [],
      showOne: false,
      mapshow: false,
      nerwstimer: '',
      // 户籍人口数
      domicileNum: 0
    }
  },

  mounted() {
    // this.submitForm()
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
    this.mapshow = true
    this.$nextTick(() => {
      this.searchNews()
      this.getReadnums()
      this.getVillageIntroduction()
      this.getgrids()
      this.drowAnalysis()
      this.getMenus()
      //村史列表
      this.getItems()
      setTimeout(() => {
        this.initChart()
      }, 1000) // 延迟 100 毫秒调用
    })
  },
  destroyed() {
    this.mapshow = false
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  },
  methods: {
    goHome() {
      this.$router.replace('/integrated')
    },
    changeMap(item) {
      this.$refs.cunMode.initData(item)
    },
    // 小平房村介绍村委详情
    goDetailInfo(item) {
      console.log(item)
      console.log('this.villageIntrod', this.villageIntrod)
      this.villageIntrod.forEach(s => {
        if (s.elementName == item) {
          let data = JSON.parse(s.elementValue)
          console.log('data======>', data)
          this.$refs.cunMode.initData(data)
        }
      })
    },
    handleTimelineClick(payload) {
      // 在这里处理Timeline组件的点击事件，并获取传递的值
      let data = payload.subItem
      data.itemtype = 'history'
      this.$refs.wgMode.initData(data)
    },
    // 村情信息echart图接口
    drowCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#F9B814', '#9523CB', '#4DB9A5'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['常住人口', '外来人口', '流动人口']
        },
        series: [
          {
            name: '人口',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [{ value: data.oftenNum, name: '常住人口' }, { value: data.foreignNum, name: '外来人口' }, { value: data.flowNum, name: '流动人口' }]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.circle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    getItems() {
      getHisItemsGroup({ historyType: 0 }).then(res => {
        this.timeLineArr = res.model
      })
    },
    getgrids() {
      getIndexStatistics().then(res => {
        if (res.code == 0) {
          this.total = res.items[0]
          setTimeout(() => {
            this.drowCircle(res.items[0])
          }, 1000)
        }
      })
    },
    //登录
    submitForm(formName) {
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            this.resetSetItem('userInfo', JSON.stringify(res.model))
            sessionStorage.setItem('token', res.model.tokenValue)
            this.searchDetails(res.model.regionId)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({ villageId: regionId }).then(res => {
        if (res.code == 0) {
          // this.totalNums = res.model.memberCount
          // this.$nextTick(() => {
          //   this.drowFunction(res.model)
          // })
        }
      })
    },
    // 关注人群接口
    drowAnalysis() {
      let _this = this
      getAnalysis().then(res => {
        if (res.code == 0) {
          let initp = [{ value: 0, name: '留守人员' }, { value: 0, name: '低保人员' }, { value: 0, name: '残疾人员' }]
          //困难人群
          _this.peopleForm = res.model?.analysisThree && res.model.analysisThree.length > 0 ? res.model.analysisThree : initp
          this.$nextTick(() => {
            this.drowCircle1(res.model.analysisThree)
          })
          const suasdm = res.model.analysisThree.reduce((lista, listb) => lista + listb.value, 0)
          _this.peopleFormNum = suasdm
        }
      })
    },
    // 关注人群Echart图接口
    drowCircle1(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#F9B814', '#9523CB', '#4DB9A5'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['残疾人员', '低保人员', '留守人员']
        },
        series: [
          {
            name: '人口',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [{ value: data[0].value, name: '残疾人员' }, { value: data[1].value, name: '低保人员' }, { value: data[2].value, name: '留守人员' }]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.circle12, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    //获取菜单
    getMenus() {
      let list = [{ tabName: '总览', value: 'index', id: '', active: false, icon: require('@/assets/img/tb1.png') }, { tabName: '3D地图', value: '3d', id: '', active: false, icon: require('@/assets/img/3D.png') }]
      this.tabList = list
    },
    // 切换tab
    handlerTab(index) {
      if (index == 2) {
        this.topTitle = '自然'
      } else {
        this.topTitle = '村情信息'
        this.getgrids()
        this.drowAnalysis()
        this.getMenus()
      }
      this.$nextTick(() => {
        if (index != this.num) {
          this.num = index
          let value
          if (index != 2) {
            value = this.tabList[index].value
          } else {
            this.tabList = [{ tabName: '总览', value: 'index', id: '', active: false, icon: require('@/assets/img/tb1.png') }]
          }
          if (value == '3d') {
            this.$router.push({ name: '3dmap' })
          } else {
            this.changeTab(index)
          }
        }
      })
    },
    changeTab(index) {
      if (index == 0) {
        this.changerow = ''
        // this.clearMarker()
      } else {
        this.dataList = []
        if (this.tabList[index] && this.tabList[index].id) {
          this.getDetatils(this.tabList[index].id)
        } else {
          this.getDetatils(18)
        }
      }
    },
    //菜单详情
    getDetatils(stid, parentId) {
      this.dataList = []
      this.serviceIndustryList = []
      this.culturalUndertList = []
      this.educationList = []
      this.guaranteeList = []
      this.domicileNum = 0
      getOthers({ stId: stid, parentId: parentId ? parentId : '' }).then(res => {
        if (res.code == '0') {
          console.log('res=?', res)
          let list = this.filterArr(res.model)
          list.forEach(s => {
            if (s.title == '人口情况') {
              s.detailList.forEach(r => {
                if (r.elementType == 'bar') {
                  this.dataList.push(r)
                }
              })
              //取户籍人口数据
              let domicileArr = s.detailList.filter(item => {
                return item.elementName == '户籍人口'
              })
              this.domicileNum = domicileArr[0].elementValue.value.slice(0, domicileArr[0].elementValue.value.length - 1)
            }

            if (s.title == '交通') {
              s.detailList.forEach(r => {
                if (r.elementType == 'checkBox') {
                  let a = r.elementValue.checkList
                  this.serviceIndustryList.push(...a)
                }
              })
            }
            if (s.title == '社会事业') {
              s.detailList.forEach(r => {
                if (r.elementType == 'checkBox' && r.elementName == '文化广场') {
                  let culturalUndert = r.elementValue.checkList
                  this.culturalUndertList.push(...culturalUndert)
                }
                if (r.elementType == 'checkBox' && r.elementName == '学校') {
                  let education = r.elementValue.checkList
                  this.educationList.push(...education)
                }
                if (r.elementType == 'checkBox' && r.elementName == '敬老院') {
                  let guarantee = r.elementValue.checkList
                  this.guaranteeList.push(...guarantee)
                }
              })
            }
          })
        }
      })
    },
    getVillageIntroduction() {
      // 村介绍
      getHomeDetail({ regionId: 50, title: '自然信息' }).then(res => {
        if (res.code == '0') {
          this.villageIntrod = res.model[0].detailList
        }
      })
    },
    filterArr(list) {
      let arr = list
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].detailList && arr[i].detailList.length) {
          arr[i].detailList.map(item => {
            if (item?.elementValue) {
              item.elementValue = JSON.parse(item.elementValue)
              if (item.elementValue.elementType == 'checkBox') {
                // 多选
                item.elementValue.checkList.forEach(el => {
                  el.id = getID()
                })
              }
              if (item.elementValue.elementType == 'media') {
                // 带有监控
                item.elementValue.list = []
                item.elementValue.list.push({
                  icon: 'dapeng',
                  lat: item.elementValue.lat,
                  lgt: item.elementValue.lgt,
                  name: item.elementValue.title,
                  articleContent: item.elementValue.articleContent
                })
              }
              if (item.elementValue.elementType == 'morelines') {
                //多折线
                item.elementValue.legend = []
                item.elementValue.yAxisData.map(it => {
                  item.elementValue.legend.push(it.name)
                  it.data = []
                  if (it.tagData.length) {
                    it.tagData.map(ii => {
                      if (ii?.num != undefined) {
                        it.data.push(ii.num)
                      }
                    })
                  }
                })
              }
            }
          })
        }
      }
      return arr
    },
    initMap() {
      var convertData = function(data) {
        var res = []
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value * 2)
            })
          }
        }
        return res
      }

      echarts.dispose(this.$refs.mapBox)
      var myChart = echarts.init(this.$refs.mapBox)

      var option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0, 153, 255, 0.82)',
          borderColor: '#FFFFCC',
          showDelay: 0,
          hideDelay: 0,
          enterable: true,
          transitionDuration: 0,
          extraCssText: 'z-index:100',
          formatter: function(params, ticket, callback) {
            //根据业务自己拓展要显示的内容
            var res = ''
            var name = params.name
            // console.log(name);
            res = "<span style='color:#fff;'>" + name + '</span>'
            return res
          }
        },
        geo: {
          map: 'xiaopingfang',
          aspectScale: 1, //长宽比
          zoom: 1.15,
          roam: false,
          itemStyle: {
            normal: {
              // areaColor: '#00FFFF', // 地图颜色
              // borderWidth: 8, // 边框的宽度
              shadowColor: '#0099ff', //  阴影颜色
              borderColor: '#00FFFF', // 边框颜色
              shadowBlur: 12, // 阴影的模糊大小
              shadowOffsetX: 0, //阴影水平方向上的偏移距离
              shadowOffsetY: 15, // 阴影垂直方向上的偏移距离
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#69c5d8' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#126caf' // 50% 处的颜色
                  }
                ],
                global: true // 缺省为 false
              },
              opacity: 1
            },
            emphasis: {
              areaColor: '#2AB8FF',
              borderWidth: 0,
              color: 'green',
              label: {
                show: false
              }
            }
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 0.8, //最小的缩放值
            max: 3 //最大的缩放值
          }
        },
        series: [
          {
            type: 'map',
            map: 'xiaopingfang', //使用
            roam: false,
            aspectScale: 1,
            // geoIndex: 0,
            zoom: 1.15,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false
              }
            },

            itemStyle: {
              normal: {
                areaColor: '#334fa3', //正常背景颜色
                // borderColor: '#4F8DBB',
                borderColor: '#8cD2',
                borderWidth: 1.5
              },
              emphasis: {
                areaColor: '#2AB8FF', //选中颜色
                borderWidth: 0,
                color: 'green',
                show: false
              }
            },
            scaleLimit: {
              //所属组件的z分层，z值小的图形会被z值大的图形覆盖
              min: 0.8, //最小的缩放值
              max: 3 //最大的缩放值
            }
          },
          {
            name: 'city',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            data: convertData(mapdata),
            symbolSize: 5,
            showEffectOn: 'render',
            //涟漪特效相关配置
            rippleEffect: {
              brushType: 'stroke',
              period: 1,
              scale: 2 //动画中波纹的最大缩放比例。
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#0098d9', //  阴影颜色
                shadowBlur: 30,
                shadowOffsetX: 0, //阴影水平方向上的偏移距离
                shadowOffsetY: 20, // 阴影垂直方向上的偏移距离
                emphasis: {
                  areaColor: '#2B91B7'
                }
              }
            },
            zlevel: 1
          },
          {
            type: 'lines',
            polyline: true,
            effect: {
              show: true,
              period: 4, //箭头指向速度，值越小速度越快
              trailLength: 0.3,
              symbolSize: 6
            },
            lineStyle: {
              width: 1.5,
              color: '#7ee0ff',
              opacity: 0.5
            },
            progressiveThreshold: 500,
            progressive: 200,
            data: coordinates,
            zlevel: 10
          }
        ]
      }

      echarts.registerMap('xiaopingfang', mapData)
      // let map = echarts.init(this.$refs.mapBox, null, {
      //   renderer: 'svg',
      // })
      // map.clear()
      myChart.setOption(option)
    },
    initChart() {
      this.initMap()
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      // console.log(this.$refs.mapBox)
      // let map1 = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      // map1.clear()
      // map1.setOption(mapOption)
    },
    showMode(item, index) {
      if (['3', '2'].includes(item.type)) {
        this.searchArticles(item.type, item, index)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item, index)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      } else if (item.type == '5') {
        this.vipCarList(item, index)
      }
      this.clearRead(item.ptype)
    },
    vipCarList(item, index) {
      vipCarDateList().then(res => {
        if (res.code == '0') {
          console.log('res', res)
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchArticles(type, data, index) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item, index) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    villageMode(name, type) {
      let item = { type: type, name: name, showType: '', regionId: this.baseForm.regionId }
      if (['cunMembers', 'rangerNum', 'forestPreNum', 'impatientNum'].includes(type)) {
        item.showType = 'peopleImg'
      } else if (['residentNum', 'oftenNum', 'foreignNum', 'flowNum'].includes(type)) {
        item.showType = 'description'
      } else if (['familyNum'].includes(type)) {
        item.showType = 'tableList'
      } else if (['griders'].includes(type)) {
        item.showType = 'gridList'
      }
      this.$refs.numMode.initData(item)
    },
    showMessage(content) {
      if (content) {
        let item = this.leftList.filter(item => {
          return item.type == content.type
        })[0]
        let index = this.leftList.findIndex(item => item.type == content.type)

        setTimeout(() => {
          item.num = item.num + 1
          this.$set(this.leftList, index, item)
        }, 10000) // 3秒后隐藏消息
      }
    },
    searchNews() {
      this.nerwstimer = setInterval(() => {
        this.getReadnums()
      }, 10000) // 滚动速度
    },
    // 视频弹窗
    openVideoPopup() {
      this.showOne = true
    },
    // 关闭预览
    close() {
      this.showOne = false
    },
    getReadnums() {
      searchReadNums().then(res => {
        if (res.code == '0') {
          this.leftList.map(i => {
            if (i.ptype == 'voxPop') i.num = res.model.voxPopNum
            if (i.ptype == 'nationalSquare') i.num = res.model.nationalSquareNum
            if (i.ptype == 'readBook') i.num = res.model.onlineReadNum
            if (i.ptype == 'releaseReq') i.num = res.model.releaseReqNum
            //贵客到访
            if (i.ptype == 'monitor') i.num = res.model.monitorNum
          })
          //终端在线人数
          this.$store.state.onlinePoeple = res.model.onlineNums
        }
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
@font-face {
  font-family: 'JDLangZhengTi';
  src: url('./assets/fonts/JDLangZhengTi.ttf') format('truetype');
}
@font-face {
  font-family: 'ShiShangZhongHeiJianTi';
  src: url('./assets/fonts/ShiShangZhongHeiJianTi.ttf') format('truetype');
}

// 公共
.lc_title {
  font-family: JDLangZhengTi;
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  margin-left: 21px;
  position: relative;
}
//所有控件-通过取消video标签的controls属性更方法
//  video::-webkit-media-controls-enclosure{
//       display: none;
//   }
.lc_title::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 32px;
  background: #0099ff;
  box-shadow: 0px 0px 13px 1px #0099ff;
  position: absolute;
  top: 0;
  left: -21px;
}
.lc_right {
  margin-right: 44px;
  cursor: pointer;
}
.one::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.one {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.panel {
  width: 100%;
  height: auto;
  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0) 100%);
  overflow: hidden;
  clear: both;
  //所有控件-通过取消video标签的controls属性更方法
  // video::-webkit-media-controls-enclosure{
  //     display: none;
  // }
  .panel_top {
    padding: 27px 0 0 31px;
  }
  .panel_title {
    font-family: JDLangZhengTi;
    font-size: 20px;
    font-weight: 400;
    color: #0099ff;
    margin-bottom: 11px;
  }
  .p_line {
    width: 319px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
  .square {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 11px;
    background-color: white;
    // border: 1px solid white;
    box-shadow: 0px 0px 12px 1px #ffffff;
  }
  .traffic_font_class {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 33px;
    width: 325px;
    background: linear-gradient(to right, #1c2d5c, rgba(28, 45, 92, 0.04));
    margin-top: 12px;
    margin-left: 31px;
  }
}
.line {
  background: linear-gradient(90deg, #0780ae 0%, rgba(7, 128, 174, 0) 100%);
  height: 2px;
  margin-top: 21px;
}
.newScreen1 {
  width: 100%;
  height: 100%;
  // background-image: url('./assets/img/bg1.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  color: #fff;
  .videobg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(41, 6, 22, 0.8), rgba(8, 16, 64, 0.8));
  }
  .top_title {
    font-family: JDLangZhengTi;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: #c4e6fe;
    text-shadow: #0099ff 0px 0px 15px;
    width: 1471.5px;
    height: 83px;
    line-height: 83px;
    margin: 0 auto;
    background-image: url('./assets/img/itop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .top_title_people {
    width: 253px;
    height: 47px;
    margin: -10px auto 0;
    background-image: url('./assets/img/itop2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-shadow: #0099ff 0px 0px 15px;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 13px;
      margin-left: 25px;
      margin-top: 5px;

      .left {
        height: 40px;
        width: 40px;
      }

      .right {
        height: 13px;
        font-family: Microsoft YaHei;
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
        color: rgba(255, 255, 255, 1);
      }
    }

    .bottom {
      height: 22px;
      line-height: 22px;
      font-family: ShiShangZhongHeiJianTi;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(255, 255, 255, 1);
      margin-right: 35px;
      margin-top: 5px;
      // box-shadow: 0px 0px 10px 1px rgba(0, 153, 255, 1);
    }
  }
  .cont {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: -84px;
    position: relative;
  }
  .nav_left {
    position: absolute;
    left: 0;
    top: 0;
    &_item {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
    }
    &_img {
      width: 59px;
      height: 59px;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
    }
    &_name {
      font-family: JDLangZhengTi;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }
    &_num {
      position: absolute;
      top: -6px;
      right: 8px;
      z-index: 99;
      background: #ff6b00;
      border-radius: 15px;
      text-align: center;
      font-family: JDLangZhengTi;
      font-size: 8px;
      font-weight: 400;
      min-width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
    }
  }
  .nav_right {
    position: absolute;
    right: 81px;
    top: 0;
    width: 477px;
  }
  .nsLeft {
    width: 400px;
    margin-top: 147px;

    .left-center .panel_cun {
      width: 349px;
      height: 272px;
      padding: 65px 0 100px 34px;

      .panel_cun_box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      .panel_cun_box1 {
        display: flex;
        align-items: center;
        width: 355px;
        height: 61px;
        margin-top: -40px;
        background: linear-gradient(to right, #1c2d5c, rgba(28, 45, 92, 0.04));
      }
      .circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 5px solid #ffffff;
      }
      .panel_cun_name {
        font-size: 12px;
        line-height: 15.96px;
        margin-left: 6px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-left: 6px;
      }
      .ageVue {
        width: 285px;
        height: 195px;
        position: relative;
        // overflow: hidden;
        margin-top: 40px;
        .echartsBg {
          position: absolute;
          width: 78%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .age_img {
          width: 42px;
          height: 42px;
        }
        .age_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 32px;
          font-weight: 400;
          line-height: 38px;
          // letter-spacing: 0em;
          // text-align: center;
          text-shadow: 0px 1px 10px #0099ff;
        }
        .echartsLeg {
          position: absolute;
          right: -30px;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .panel_cun_name {
            font-size: 14px;
            line-height: 18.48px;
          }
          .panel_cun_num {
            font-family: ShiShangZhongHeiJianTi;
            font-size: 22px;
            font-weight: 400;
            line-height: 26px;
            text-shadow: 0px 1px 10px #0099ff;
            margin-top: 4px;
          }
        }
      }
    }
    .line_box {
      width: 345px;
      height: 194px;
      margin: 30px 0 22px 13px;
    }
  }
  .nscenter {
    width: 1103px;
    margin-top: 147px;
    .mapCity {
      width: 710px;
      margin: 0 auto;
      height: 507px;
    }
    .center_bottom {
      // display: flex;
      // align-items: flex-start;
      .center_bleft {
        flex: 1.5;
        margin-right: 27px;
        margin-left: 3px;
      }
      .center_nright {
        flex: 1;
        margin-right: 24px;
      }
    }
  }
  .natureInfo2 {
    height: 50px;
    margin-top: 20px;
    .natureImg1 {
      margin-left: 40px;
      vertical-align: top;
      // margin-top: 5px;
    }
    .natureImg2 {
      color: rgba(196, 230, 254, 0.2);
      vertical-align: top;
    }
  }
  .nsright {
    width: 400px;
    margin-top: 147px;
    .right_item {
      width: 354px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin: 14px 0 0 37px;
      &_box {
        // flex: 1;
        margin-bottom: 21px;
        .item_title {
          margin-bottom: 8px;
        }
        .muNum {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          text-shadow: 0px 1px 10px #0099ff;
          margin-right: 6px;
        }
        .mu {
          color: #0099ff;
        }
      }
    }
  }
  .nsbelow {
    width: 400px;
    margin-top: 23px;
    .right_item {
      width: 354px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin: 14px 0 0 37px;
      &_box {
        flex: 1;
        margin-bottom: 21px;
        .item_title {
          margin-bottom: 8px;
        }
        .muNum {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          text-shadow: 0px 1px 10px #0099ff;
          margin-right: 6px;
        }
        .mu {
          color: #0099ff;
        }
      }
    }
    .left-center .panel_cun {
      width: 349px;
      height: 272px;
      padding: 65px 0 95px 34px;
      .panel_cun_box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      .circle {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 5px solid #ffffff;
      }
      .panel_cun_name {
        font-size: 12px;
        line-height: 15.96px;
        margin-left: 6px;
      }
      .panel_cun_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-left: 6px;
      }
      .ageVue {
        width: 285px;
        height: 195px;
        position: relative;
        // overflow: hidden;
        margin-top: 40px;
        .echartsBg {
          position: absolute;
          width: 78%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .age_img {
          width: 42px;
          height: 42px;
        }
        .age_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 32px;
          font-weight: 400;
          line-height: 38px;
          // letter-spacing: 0em;
          // text-align: center;
          text-shadow: 0px 1px 10px #0099ff;
        }
        .echartsLeg {
          position: absolute;
          right: -30px;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .panel_cun_name {
            font-size: 14px;
            line-height: 18.48px;
          }
          .panel_cun_num {
            font-family: ShiShangZhongHeiJianTi;
            font-size: 22px;
            font-weight: 400;
            line-height: 26px;
            text-shadow: 0px 1px 10px #0099ff;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
.natureInfo3 {
  height: 100px;
  width: 210px;
  .columnLayout {
    font-family: Microsoft YaHei;
    margin-left: 45px;
    column-count: 2; /* 设置列数为2 */
    line-height: 43.2px;
    color: #0099ff;
    size: 18px;
    display: flex;
    flex-direction: column;
  }
}
.nav_right .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #ceb77b !important;
  height: 0.15rem !important;
}
.nav_right .el-table .el-table__cell {
  padding: 0 !important;
  height: 0.15rem !important;
  font-size: 0.12rem;
}
.nav_right .el-table thead {
  color: #fff !important;
  font-size: 0.12rem !important;
}
.nav_right > .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
.nav_right .el-table tr {
  color: #fff !important;
  background: transparent !important;
}

/* 用来设置当前页面element全局table 选中某行时的背景色*/
.nav_right .el-table__body tr.current-row > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
.nav_right .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.nav_right .el-table__body-wrapper {
  overflow: hidden !important;
}
.nav_right .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none !important;
}
.nav_right .el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
.nav_right .el-table .cell {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.nav_right .el-table::before {
  height: 0 !important;
}
</style>