<template>
    <ul class="timeline-wrapper">
      <li class="timeline-item">
        <div class="timeline-box">
          <div class="long-line">
            <div v-for="(subItem, index) in timelineList" :key="subItem.id" class="sub-item-box" style="display: flex;"  @click.stop="seeHis(subItem,index)">
              <!-- 根据奇数偶数来判断向上还是向下 -->
              <div :class="`sub-line-box ${ index % 2 == 0 ? 'top-line-box' : 'bottom-line-box' }`" v-show="subItem.historyName">
                <div style="height: 43px;" :class="`children-line-box ${ index % 2 == 0 ? 'top-line' : 'bottom-line' }`"></div>
                <div :class="`children-box-font ${ index % 2 == 0 ? 'top-children-font' : 'bottom-children-font' }`"> {{ subItem.groupDate }} </div>
                <div style="width: 147px; height: 70px;" :class="`children-box ${ index % 2 == 0 ? 'top-children-box' : 'bottom-children-box' }`"> {{ subItem.historyName }} </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
</template>

<script type="text/babel">
import Vue from "vue";
import { eventBus } from '@/main.js'
export default Vue.component("Timeline", {
  name: "Timeline",
  props: {
    timelineList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {},
  methods: {
    seeHis(subItem, index) {
      // 使用$emit触发自定义事件，并将index和indexs作为参数传递
      this.$emit('handleTimelineClick', { subItem, index })
    },
    scrollEvent(e) {
      this.$emit("scrollEvent", e);
    },
    handleBottomClick() {
      this.$emit("handleBottomClick");
    },
  },
});
</script>
<style scoped lang="scss">
ul.timeline-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 165px 20px;
  white-space: nowrap;
  overflow-x: scroll;
}

/* 时间线 */
.timeline-item {
  position: relative;
  display: inline-block;
  .timeline-box {
    text-align: center;
    display: flex;
    .out-circle {
      width: 16px;
      height: 16px;
      background: #ffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      .in-circle {
        width: 8px;
        height: 8px;
        margin: 0 auto;
        border-radius: 50%;
      }
      .timeline-date {
        color: #fff;
        margin-top: 40px;
        .father-text {
          font-weight: 900;
          font-size: 16px;
          margin-left: -15px;
        }
      }
    }

    .long-line {
      // width: 300px;
      height: 2px;
      background: #ffff;
      box-shadow: 0 0 2px 1px #ffff;
      display: flex;
      flex-direction: revert;
      justify-content: space-around;
      position: relative;
      .sub-item-box {
        margin-top: -5px;
        // position: relative;
        .sub-line-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .children-line-box {
            width: 0px;
            border-left: 1px solid #ffff;
          }
          .children-box {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: break-spaces;
            // text-align: center;
            padding: 1px;
          }
          .children-box-font {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: break-spaces;
            // text-align: center;
            padding: 1px;
          }
        }
        .top-line-box {
          margin-top: -64px;
          height: 60px;
        }
        .bottom-line-box {
          margin-top: 5px;
          height: 150px;
        }
        .top-line {
          top: -64px;
          height: 38px;
          position: relative;
          background-color: #ffff;
        }
        .top-line::after {
          content: "";
          position: absolute;
          top: -29px;
          left: 50%;
          transform: translateX(-50%) translateY(700%);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #ffff;
        }
        .bottom-line {
          height: 180px;
          position: relative;
          background-color: #ffff;
        }
        .bottom-line::after {
          content: "";
          position: absolute;
          top: -7px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #ffff;
        }
        .top-children-box {
          align-items: end;
          font-family: ShiShangZhongHeiJianTi;
          color: #0099FF;
          font-size: 12px;
          text-shadow: 0 0 5px #0099FF;
          margin-top: -110px;
          width: 150px;
          text-align: left;
          cursor: pointer;
        }
        .top-children-font {
          margin-top: -147px;
          width: 130px;
          font-family: ShiShangZhongHeiJianTi;
          color: #ffffff;
          font-size: 30px;
          text-shadow: 0 0 5px #FFFFFF;
        }
        .bottom-children-box {
          margin-bottom: -20px;
          width: 150px;
          font-family: ShiShangZhongHeiJianTi;
          color: #0099FF;
          font-size: 12px;
          text-shadow: 0 0 5px #0099FF;
          text-align: left;
          vertical-align: top;
          cursor: pointer;
        }
        .bottom-children-font {
          width: 130px;
          font-family: ShiShangZhongHeiJianTi;
          color: #ffffff;
          font-size: 30px;
          text-shadow: 0 0 5px #FFFFFF;
        }
      }
    }
    .long-line::after {
      content: "";
      position: absolute;
      top: -4.5px;
      left: 99%;
      transform: translateX(-50%) ;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ffff;
      transform: rotate(90deg); 
    }
    .long-line::before {
      content: "";
      position: absolute;
      top: -7px;
      left: -10px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ffff;
    }
  }

  .timeline-content {
    box-sizing: border-box;
    margin-left: 20px;
    height: 106px;
    padding: 0 0 0 20px;
    text-align: left;
    margin-bottom: 30px;

    .timeline-title {
      font-size: 14px;
      word-break: break-all;
      margin-bottom: 16px;
      color: #333;
      font-weight: 500;
      /*display: inline;*/
    }
    .timeline-desc {
      font-size: 14px;
      color: #999999;
    }
  }
}
.lc_right_top {
  margin-right: 44px;
  margin-left: 40px;
  margin-bottom: 7px;
}

.lc_right_bottom {
  margin-right: 44px;
  margin-left: 40px;
  margin-bottom: 85px;
}
.timeline-item:last-of-type .timeline-content {
  margin-bottom: 0;
}
</style>